.icon-button {
  background: transparent;
  color: inherit;
  cursor: pointer;
}

.icon-mobile-button {
  background-color: $waterkwantiteit-xxdark;
  color: $white;
  padding: rem(14) rem(16) rem(18) rem(16);
  height: rem(48);
  border-top-right-radius: rem(12);
  border-bottom-left-radius: rem(12);
  font-size: rem(16);
  line-height: rem(24);
  cursor: pointer;

  &--shadow {
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.16);
  }
}

.slider-pagination {
  display: flex;
  justify-content: space-between;
  &__button {
    @extend %button--primary !optional;
  }
  &__dots {
    display: flex;
    align-items: center;
    padding: 0 rem(20);
    border-radius: rem(12);
    background-color: blue(medium);
  }
  &__dot-button {
    margin: rem(16) rem(4);
    width: rem(8);
    height: rem(8);
    border-radius: rem(6);
    position: relative;
    &--active {
      width: rem(12);
      height: rem(12);
    }
    &:not(&--active) {
      cursor: pointer;
    }
  }
  &--purple {
    .slider-pagination {
      &__button {
        background-color: $overkoepelend-xdark;
      }
      &__dots {
        background-color: purple(xxdark);
      }
      &__dot-button {
        background-color: $overkoepelend-xlight;
      }
      &__dot-button--active {
        background-color: purple(xxlight);
      }
    }
  }
  &--red {
    .slider-pagination {
      &__button {
        background-color: $waterveiligheid-xxdark;
      }
      &__dots {
        background-color: $waterveiligheid-xxdark;
      }
      &__dot-button {
        background-color: $waterveiligheid-xlight;
      }
      &__dot-button--active {
        background-color: $waterveiligheid-xxlight;
      }
    }
  }
  &--yellow {
    .slider-pagination {
      &__button {
        background-color: $organisatie-bestuur-xxdark;
      }
      &__dots {
        background-color: yellow(xxdark);
      }
      &__dot-button {
        background-color: $organisatie-bestuur-light;
      }
      &__dot-button--active {
        background-color: yellow(xlight);
      }
    }
  }
  &--green {
    .slider-pagination {
      &__button {
        background-color: $waterkwaliteit-xxdark;
      }
      &__dots {
        background-color: $waterkwaliteit-xxdark;
      }
      &__dot-button {
        background-color: $waterkwaliteit-xlight;
      }
      &__dot-button--active {
        background-color: $waterkwaliteit-xxlight;
      }
    }
  }
  &--brown {
    .slider-pagination {
      &__button {
        background-color: $waterketen-xxdark;
      }
      &__dots {
        background-color: $waterketen-xxdark;
      }
      &__dot-button {
        background-color: $waterketen-xlight;
      }
      &__dot-button--active {
        background-color: $waterketen-xxlight;
      }
    }
  }
  &--blue {
    .slider-pagination {
      &__button {
        background-color: $waterkwantiteit-xxdark;
      }
      &__dots {
        background-color: $waterkwantiteit-xxdark;
      }
      &__dot-button {
        background-color: $waterkwantiteit-xlight;
      }
      &__dot-button--active {
        background-color: $waterkwantiteit-xxlight;
      }
    }
  }
}
.video-modal {
  display: none;
  position: absolute;
  display: none;
  left: -99999px;
  bottom: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-around;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  padding: 0 rem(40);

  &--open {
    left: 0;
    display: flex;
    position: fixed;
    pointer-events: auto;
    opacity: 1;
    z-index: 100;
    overflow-y: scroll;
  }

  &__close-icon {
    background-color: transparent;
    position: absolute;
    top: rem(8);
    right: rem(8);
    cursor: pointer;
    @include media-breakpoint-up(m) {
      top: rem(24);
      right: rem(24);
    }
  }

  &__youtube {
    &:focus,
    &:focus-visible {
      border-radius: rem(4);
      border: rem(4) solid $black;
      outline: rem(4) solid $white;
      }
  }
  iframe {
    max-width: 100%;
    *:focus-visible {
      outline: 2px solid white;
    }
  }
}

*,
*::after,
*::before {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0;
  padding: 0;
  text-decoration: none;
  word-break: break-word;
  &:focus-visible {
    outline: 2px solid $black !important;
  }
}

html {
  font: normal 100%/1.5 sans-serif, serif;
  scroll-behavior: smooth;
  font-family: "TheMixC5", sans-serif;
}

body,
button {
  font-family: "TheMixC5", sans-serif;
}
body::first-letter {
  text-transform: capitalize;
}

ul,
ol {
  margin-left: rem(16);
  list-style: none;
}

p + p {
  margin-top: rem(24);
}

.uppercase {
  text-transform: uppercase;
}

.visually-hidden {
  display: none;
}
.skip-link {
  position: absolute;
  left: -9999px;
  top: -9999px;
  &:focus {
    position: static;
    left: 0;
    top: 0;
    background-color: #000;
    color: #fff;
    padding: 8px;
    z-index: 1000;
  }
}
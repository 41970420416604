.area-filter {
  background-color: $white;
  display: block;

  &__content {
    display: none;
  }

  &__content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(4) 0;
  }

  &__title {
    font-size: rem(20);
    line-height: rem(24);
    font-weight: bold;
  }

  &__button {
    background-color: transparent;
    color: $waterkwantiteit-xxdark;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity $transition-duration $transition-timing-function;
    padding: rem(16);
    min-width: rem(146);
    &:focus,
    &:hover,
    &:active {
      color: $waterkwantiteit-xxdark;
    }
  }

  &__button-icon {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
    height: rem(40);
    margin-right: rem(16);
    font-weight: 500;
  }

  &__button--active {
    .area-filter__button-icon {
      outline: rem(3) solid $waterkwantiteit-xxdark;
      border-radius: rem(8);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: rem(-8);
        right: rem(-8);
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none'%3E%3Ccircle cx='11.314' cy='11.314' r='7' transform='rotate(45 11.314 11.314)' fill='%23fff' stroke='%23213165' stroke-width='2'/%3E%3Cpath fill='%23fff' stroke='%23213165' stroke-width='.667' d='m12.728 9.428.472.472-3.3 3.3-.472-.472z'/%3E%3Cpath fill='%23fff' stroke='%23213165' stroke-width='.667' d='m13.2 12.728-.472.471-3.3-3.3.471-.471z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        width: rem(16);
        height: rem(16);
      }
    }
  }

  @include media-breakpoint-up(l) {
    &__content-mobile {
      display: none;
    }

    &__content {
      display: flex;
      align-items: center;
      height: rem(80);
    }

    &__list {
      display: flex;
      margin: 0;
    }
  }
}

.area-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;

  ul {
    margin: 0;
  }

  &--open {
    pointer-events: auto;
    z-index: 10;
    height: 100%;
    overflow: scroll;
    .area-modal {
      &__content {
        top: rem(24);
      }
    }
  }

  &__content {
    position: absolute;
    transition: bottom $transition-duration $transition-timing-function;
    bottom: -100vh;
    background-color: $waterkwantiteit-xxlight;
    border-top-left-radius: rem(40);
    border-top-right-radius: rem(40);
    width: 100%;
    height: max-content;
  }

  &__text-button {
    padding-left: rem(32) !important;
    padding-right: rem(32) !important;
    margin-top: rem(11);
    margin-bottom: rem(32);
  }

  &__title-bar {
    padding: rem(20) 0 rem(12);
    border-bottom: rem(1) solid $waterkwantiteit-xxdark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__title {
    color: $waterkwantiteit-xxdark;
  }

  &__title-button {
    color: $waterkwantiteit-xxdark;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

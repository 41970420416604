.Splash {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.Splash-header {
  align-items: center;
  display: flex;
  margin-top: rem(16);
  justify-content: center;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(l) {
    margin-top: 0;
    height: rem(124);
  }
}

.Splash-body {
  color: blue(xxdark);
  margin-top: rem(8);
  position: relative;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(l) {
    margin: auto;
  }
}

.Splash-subtitle {
  font-size: rem(24);
  line-height: rem(32);
  margin-bottom: rem(24);
  text-transform: uppercase;

  @include media-breakpoint-up(l) {
    font-size: rem(40);
    line-height: rem(48);
    margin-bottom: rem(56);
  }
}

.Splash-actions {
  .button {
    margin-bottom: rem(16);
  }
}

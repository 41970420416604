.dispositionCard {
  @include box-shadow;
  margin: rem(18) 0 rem(64);
  border-radius: rem(24);
  position: relative;

  @include media-breakpoint-up(l) {
    margin-top: 0;
  }

  &__theme {
    color: $waterkwantiteit-xxdark;
    background-color: $white;
    font-weight: bold;
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__mobile-button {
    position: absolute;
    left: calc(50% - #{rem(16)});
    top: rem(-18);
    background-color: $white;
    color: inherit;
    cursor: pointer;
    width: rem(42);
    height: rem(32);
    border-top-left-radius: rem(21);
    border-top-right-radius: rem(21);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(5);
    z-index: 0;
  }

  &__cluster {
    position: relative;
    font-weight: bold;
    margin-top: rem(-24);
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
  }

  &__title {
    @include bg(blue, xxdark);
    position: relative;
    font-weight: bold;
    margin-top: rem(-24);
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
  }

  &__title-inner {
    min-height: rem(48);
    height: fit-content;
    overflow: hidden;
  }

  &__content {
    @include bg(blue, xdark);
    font-weight: bold;
    margin-top: rem(-24);
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    position: relative;
    a {
      color: white;
      text-decoration: underline;
      text-underline-offset: rem(4);
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__content-inner {
    height: rem(48);
    overflow: hidden;
    display: block;
    padding-right: rem(32);
    transition: height $transition-duration $transition-timing-function;
  }

  &__button {
    position: absolute;
    top: rem(12);
    right: rem(24);
  }

  &__year {
    position: relative;
    background-color: $waterkwantiteit-medium;
    color: $white;
    font-weight: bold;
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: rem(-24);
  }

  &__area {
    position: relative;
    @include bg(blue, xxlight);
    padding: rem(12) rem(24) rem(36);
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    margin-top: rem(-24);

    font-weight: bold;
  }
  &__area-expand {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 0;
    overflow: hidden;
    & > * {
      flex: 1 0 50%;
      align-self: flex-start;
    }
    transition: height $transition-duration $transition-timing-function;
  }
  &__image {
    width: 100%;
    padding-top: 100%;
    margin-top: rem(-24);
    position: relative;
    border-radius: rem(24);
    background: no-repeat center/cover;
    transition: padding-top $transition-duration $transition-timing-function;
  }
  &__image--open-content {
    padding-top: calc(100% - #{rem(216)});
  }
  &__image--open-area {
    padding-top: calc(100% - #{rem(216)});
  }

  &--purple {
    .dispositionCard {
      &__theme {
        color: $overkoepelend-xxdark;
      }
      &__cluster {
        background-color: $overkoepelend-medium;
        color: $overkoepelend-xxlight;
      }
      &__title {
        @include bg(purple, xxdark);
      }
      &__content {
        @include bg(purple, xdark);
      }
      &__year {
        background-color: $overkoepelend-medium;
      }
      &__area {
        @include bg(purple, xxlight);
      }
    }
  }
  &--red {
    .dispositionCard {
      &__theme {
        color: $waterveiligheid-xxdark;
      }
      &__cluster {
        background-color: $waterveiligheid-medium;
        color: $waterveiligheid-xxlight;
      }
      &__title {
        @include bg(red, xxdark);
      }
      &__content {
        @include bg(red, xdark);
      }
      &__year {
        background-color: $waterveiligheid-medium;
      }
      &__area {
        background-color: $waterveiligheid-xxlight;
        color: #5A0E1C;
      }
    }
  }
  &--yellow {
    .dispositionCard {
      &__theme {
        color: $organisatie-bestuur-xxdark;
      }
      &__title {
        @include bg(yellow, xxdark);
      }
      &__cluster {
        background-color: $organisatie-bestuur-dark;
        color: $organisatie-bestuur-xxlight;
      }
      &__content {
        background-color: $organisatie-bestuur-medium;
        color: $organisatie-bestuur-xxdark;
      }
      &__year {
        background-color: $organisatie-bestuur-light;
        color: $organisatie-bestuur-xxdark;
      }
      &__area {
        @include bg(yellow, xxlight);
      }
    }
  }
  &--green {
    .dispositionCard {
      &__theme {
        color: $waterkwaliteit-xxdark;
      }
      &__cluster {
        background-color: $waterkwaliteit-medium;
        color: $waterkwaliteit-xxlight;
      }
      &__title {
        @include bg(green, xxdark);
      }
      &__content {
        @include bg(green, xdark);
      }
      &__year {
        background-color: $waterkwaliteit-dark;
      }
      &__area {
        @include bg(green, xxlight);
      }
    }
  }
  &--brown {
    .dispositionCard {
      &__theme {
        color: $waterketen-xxdark;
      }
      &__cluster {
        background-color: $waterketen-medium;
        color: $waterketen-xxlight;
      }
      &__title {
        @include bg(brown, xxdark);
      }
      &__content {
        @include bg(brown, xdark);
      }
      &__year {
        background-color: $waterketen-dark;
      }
      &__area {
        @include bg(brown, xxlight);
      }
    }
  }
  &--blue {
    .dispositionCard {
      &__cluster {
        background-color: $waterkwantiteit-medium;
        color: $waterkwantiteit-xxlight;
      }
    }
    .dispositionCard__year {
      color: $waterkwantiteit-xxdark;
    }
  }
  &__area-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: column;
    margin-bottom: rem(8);
    @include media-breakpoint-up(m) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
  &__area-list-item {
    flex: 1 1 50%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: rem(16);
    font-size: rem(16);
    line-height: rem(24);
    @include media-breakpoint-up(m) {
      width: 50%;
    }
    & > svg {
      margin-right: rem(16);
    }
    &--active {
      svg {
        outline: rem(3) solid $waterkwantiteit-xxdark;
        border-radius: rem(8);
      }
    }
  }
  &--modal {
    .dispositionCard {
      &__content-inner {
        height: rem(264);
      }

      &__area-expand {
        height: rem(216);
      }
    }
  }
  &__content-inner--open {
    height: fit-content;
  }

  &__area-expand--open {
    min-height: rem(216);
    height: fit-content;
  }
}

.disposition-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(48);
  margin-bottom: rem(40);
  &__button {
    @extend %button--primary !optional;
    opacity: 0.8;
  }
  &__label {
    background-color: blue(xxdark);
    color: $white;
    font-weight: bold;
    padding: rem(4) rem(8);
    border-radius: rem(8);
  }
  @include media-breakpoint-up(l) {
    margin-bottom: rem(80);
  }
}
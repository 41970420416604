.theme-filter-overlay {
  position: absolute;
  top: rem(80);
  left: 0;
  right: rem(16);
  background-color: $waterkwantiteit-xxdark;
  color: $white;
  display: none;
  padding: rem(24);
  z-index: 5;
  border-bottom-left-radius: rem(20);
  flex-wrap: wrap;

  &__list {
    margin: 0;
    padding-bottom: rem(16);
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: rem(16);
    margin-bottom: rem(8);
  }

  &--open {
    display: flex;
  }

  &__backdrop {
    display: none;
    position: fixed;
    background-color: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .icon-mobile-button {
    background-color: $waterkwantiteit-xlight;
    position: absolute;
    bottom: rem(12);
    right: rem(12);
    display: flex;
    align-items: center;
    color: $waterkwantiteit-xxdark;
    &:hover,
    &:active,
    &:focus {
      span {
        text-decoration: underline;
      }
    }
    span {
      margin-right: rem(6);
    }
  }

  @include media-breakpoint-up(l) {
    &__backdrop--open {
      display: block;
    }
  }

  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;
  }
}

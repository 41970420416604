.Result {
  background-color: blue(xxlight);
  border-radius: rem(8);
  color: blue(xxdark);
  display: block;

  &:hover {
    background-color: blue(xdark);
    color: white();
  }

  article {
    display: flex;
    height: 100%;
  }

  &--yellow {
    &:hover {
      background-color: $organisatie-bestuur-medium;
      color: $organisatie-bestuur-xxdark;
      .Result-cluster {
        color: $organisatie-bestuur-xxdark !important;
      }
    }
  }
  &--brown {
    &:hover {
      background-color: brown(xdark);
    }
  }
  &--purple {
    &:hover {
      background-color: purple(xdark);
    }
  }
  &--red {
    &:hover {
      background-color: red(xdark);
    }
  }
  &--green {
    &:hover {
      background-color: green(xdark);
    }
  }
}

.Result-image {
  border-radius: rem(8);
  box-shadow: 0 rem(10) rem(24) rgba(0, 0, 0, 0.16);
  flex-shrink: 0;
  overflow: hidden;
  width: 34%;
  height: auto;
  background: no-repeat center/cover;
  @include media-breakpoint-up(m) {
    width: 39%;
  }
}

.Result-title {
  font-size: rem(20);
  line-height: rem(24);
  word-break: break-word;
}

.Result-body {
  padding: rem(12)  0 rem(12) rem(16);
}

.Result-cluster {
  @include transition(background-color color);
  color: blue(xxdark);
  margin-top: rem(12);

  .Result:hover & {
    color: white();
  }

  div {
    text-transform: capitalize;
  }
}

@import "~@deidee/desass/src/sass/style.scss";

@import "scss/variables";
@import "scss/functions";
@import "scss/mixins";

// fonts
@font-face {
  font-family: "TheMixC5";
  src: url("./fonts/TheMixC5-5_Plain.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "TheMixC5";
  src: url("./fonts/TheMixC5-5iPlainItalic.woff2") format("woff2");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "TheMixC5";
  src: url("./fonts/TheMixC5-6_SemiBold.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "TheMixC5";
  src: url("./fonts/TheMixC5-7_Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}

@import "~@deidee/deflex/scss/deflex";

@import "scss/components/area-filter";
@import "scss/components/area-filter-modal";
@import "scss/components/base";
@import "scss/components/bg";
@import "scss/components/block";
@import "scss/components/button";
@import "scss/components/context-popper";
@import "scss/components/checkbox";
@import "scss/components/cluster-detail";
@import "scss/components/cluster-index";
@import "scss/components/disposition-index";
@import "scss/components/disposition-card";
@import "scss/components/disposition-modal";
@import "scss/components/disposition-pagination";
@import "scss/components/disposition-slider";
@import "scss/components/icon-button";
@import "scss/components/image";
@import "scss/components/link";
@import "scss/components/mobile-filter";
@import "scss/components/result";
@import "scss/components/search";
@import "scss/components/slider";
@import "scss/components/slider-pagination";
@import "scss/components/splash";
@import "scss/components/svg";
@import "scss/components/theme-info";
@import "scss/components/theme-filter";
@import "scss/components/theme-filter-overlay";
@import "scss/components/typography";
@import "scss/components/video-modal";
@import "scss/components/accessibility-detail";

@import "scss/debug";

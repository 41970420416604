.button {
  @include transition(background-color color);
  align-items: center;
  background-color: $button-background;
  border-radius: 0 rem(20);
  color: blue(xxdark);
  display: inline-flex;
  font-weight: 500;
  height: rem(44);
  justify-content: center;
  min-width: rem(175);
  padding-left: rem(24);
  padding-right: rem(24);
  text-decoration: none;
  text-transform: uppercase;

  > svg {
    margin-right: rem(8);
  }

  &:hover {
    background-color: $button-background-active;
    color: $waterkwantiteit-xxdark;
  }
}

.button--alt {
  border-radius: rem(20) 0;
}

.button--features {
  position: relative;
  margin: rem(8) auto;
  border-top-right-radius: 0;
  display: flex;
  align-items: center;
  min-width: rem(175);
  max-width: max-content;
  gap: rem(8);
  z-index: 2;
  @include media-breakpoint-up(l) {
    border-top-right-radius: rem(20);
    width: auto;
    margin: 0 0 0 auto;

    > svg {
      margin-right: rem(8);
    }
  }
  > svg {
    margin-right: 0;
  }
  .button__label {
    display: inline-block;
    margin-top: rem(4);
  }
}
.button--features.dark {
  background-color: $waterkwantiteit-xxdark;
  color: white;
  font-weight: 500;
  text-decoration: none;
  @include media-breakpoint-up(l) {
    margin: 0 auto;
  }
  &:hover,
  &:active {
    background-color: $waterkwantiteit-light;
    color: $waterkwantiteit-xxdark;
  }
}

%button--primary {
  color: $white;
  cursor: pointer;
  background-color: $waterkwantiteit-xdark;
  border-bottom-right-radius: rem(10);
  width: rem(48);
  display: flex;
  align-items: center;
  justify-content: center;
}

.siteLink {
  color: $waterkwantiteit-xxdark;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
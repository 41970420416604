.DispositionIndex {
  background-color: blue(xxlight);
  color: blue(xxdark);
  min-height: 100vh;
}

.DispositionIndex-header {
  align-items: center;
  background-color: blue(medium);
  display: flex;
  height: rem(123);
}

.DispositionIndex-areas {
  align-items: center;
  background-color: white();
  display: flex;
  height: rem(80);
}

.DispositionIndex-filter {
  align-items: center;
  display: flex;
}

.DispositionIndex__counter {
  display: none;
}

.Disposition-search {
  display: flex;
  padding: rem(12) 0;
  margin-bottom: rem(24);
  border-bottom: rem(1) solid $waterkwantiteit-xdark;
  align-items: center;
  &__wrapper {
    display: flex;
    width: 100%;
    gap: rem(8);
    align-items: center;
  }
  input {
    font-weight: bold;
    outline: none;
    background-color: transparent;
    flex: 1;
    &::placeholder {
      color: $waterkwantiteit-xxdark;
      font-weight: bold;
    }
    &:focus-visible,
    &:focus {
      outline: 3px solid $black ;
    }
  }
  svg {
    fill: $waterkwantiteit-xxdark;
  }
}

@include media-breakpoint-up(l) {
  .DispositionIndex__counter {
    display: block;
    padding: rem(32) rem(16) rem(24);
  }

  .Disposition-search {
    border-bottom: 0;
    margin-bottom: 0;
    padding: rem(32) 0 rem(24);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    &__wrapper {
      width: auto;
      padding: rem(8) rem(24);
      border-radius: 0 rem(20);
      border: 2px solid $waterkwantiteit-xxdark;
    }
  }
}

.AccessibilityDetail {
  background-color: $waterkwantiteit-xxlight;
  min-height: 100vh;
  color: $waterkwantiteit-xxdark;

  &__content {
    padding-top: rem(24);
    padding-bottom: rem(24);
    @include media-breakpoint-up(m) {
      padding-top: rem(72);
      padding-bottom: rem(72);
    }
  }
  &__html {
    margin-bottom: rem(40);
  }

  /* Base h1 */
  h1 {
    font-size: 40px;
    font-weight: 520;
    line-height: 48px;
    margin-bottom: rem(16);
  }

  /* h2 - 90% of h1 size */
  h2 {
    font-size: 36px;
    font-weight: 520;
    line-height: 43.2px;
    margin-bottom: rem(14);
  }

  /* h3 - 80% of h1 size */
  h3 {
    font-size: 32px;
    font-weight: 520;
    line-height: 38.4px;
    margin-bottom: rem(12);
  }

  /* h4 - 70% of h1 size */
  h4 {
    font-size: 28px;
    font-weight: 520;
    line-height: 33.6px;
    margin-bottom: rem(10);
  }

  /* h5 - 60% of h1 size */
  h5 {
    font-size: 24px;
    font-weight: 520;
    line-height: 28.8px;
    margin-bottom: rem(8);
  }

  /* h6 - 50% of h1 size */
  h6 {
    font-size: 20px;
    font-weight: 520;
    line-height: 24px;
    margin-bottom: rem(8);
  }

  p {
    margin-bottom: rem(16);
  }

  ul, ol {
    padding-left: rem(16);
    li {
      margin-bottom: rem(8);
    }
  }
  ul {
    list-style: initial;
  }
  ol {
    list-style-type: decimal;
  }
  a {
    color: $waterkwantiteit-xxdark;
    font-weight: 530;
    text-decoration: underline;
    &:active,
    &:hover {
      text-decoration: unset;
    }
  }
}